import * as i18next from 'i18next';
import * as i18nextXHRBackend from 'i18next-xhr-backend';
var i18nextCore = function (locale, baseUrl, cb) {
    i18next.use(i18nextXHRBackend).init({
        lng: locale,
        fallbackLng: 'en',
        keySeparator: '$',
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: baseUrl + "assets/locale/messages_{{lng}}.json",
            crossDomain: true,
        },
    }, function (err, t) { return cb(err, t, i18next); });
};
export default function i18n(_a) {
    var locale = _a.locale, _b = _a.baseUrl, baseUrl = _b === void 0 ? '' : _b;
    return new Promise(function (resolve, reject) {
        i18nextCore(locale, baseUrl, function (err, t, i18nInstance) {
            return err ? reject(err) : resolve({ t: t, i18nInstance: i18nInstance });
        });
    });
}
