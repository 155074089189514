var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EditorEvent } from '../enums/editorEvents';
export var setComponentsManifest = function (_a) {
    var showUpgradeButton = _a.showUpgradeButton, _b = _a.translations, mainAction1Label = _b.mainAction1Label, mainAction2Label = _b.mainAction2Label;
    return ({
        controllersStageData: {
            WixPay: {
                default: {
                    gfpp: {
                        desktop: {
                            mainAction1: { actionId: EditorEvent.OpenPaymentSettings, label: mainAction1Label },
                            mainAction2: { actionId: EditorEvent.ConnectPaymentMethods, label: mainAction2Label },
                            iconButtons: __assign({ link: 'HIDE' }, showUpgradeButton ? {
                                upgrade: 'SITE_UPGRADE',
                            } : {}),
                            helpId: 'ff0c64a8-98f0-470a-9135-622f1504de83',
                        },
                    },
                    behavior: {
                        rotatable: false,
                    },
                },
            },
        },
    });
};
