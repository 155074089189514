var getEnvName = function () { return process.env.NODE_ENV || ''; };
export var isProduction = function () { return getEnvName() !== 'development' && getEnvName() !== 'test'; };
export var getAppUrl = function (origin) {
    if (origin === void 0) { origin = 'editor-app'; }
    return isProduction()
        ? "https://static.parastorage.com/services/pay-button-worker/" + getAppVersion() + "/" + origin + ".bundle.min.js"
        : "https://localhost:3203/" + origin + ".bundle.js";
};
export var getBaseUrl = function () {
    var url = (getAppUrl().match(/((?:\/[^\/]+)+)(?=\/[^\/]+)/) || [null])[0];
    return "" + ((url && url.substring(1)) || '');
};
export var getAppVersion = function () {
    // we are not actually accessing window here, it's replaced via webpack's define plugin
    if (isProduction()) {
        return window.__CI_APP_VERSION__.replace('-SNAPSHOT', '');
    }
    return 'local-development';
};
export var getStaticsBaseUrl = function () { return getBaseUrl() + "/statics"; };
